<template>
<!-- 内推人信息 -->
  <div id="information" class="form">
    <el-form :model="form" label-width="120px" :rules="rules" ref="form">
      <el-form-item label="内推用户名" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="企业邮箱" prop="email">
        <el-input v-model="form.email">
          <el-button
            v-if="originData.email != form.email"
            slot="append"
            :disabled="isSend"
            @click="sendVerify"
            >{{ isSend ? `${countDown}秒后再次发送` : "发送验证码" }}</el-button
          >
        </el-input>
      </el-form-item>
      <el-form-item
        label="验证码"
        prop="code"
        v-if="originData.email != form.email"
      >
        <el-input style="width: 200px" v-model="form.code"></el-input>
      </el-form-item>
      <el-form-item label="毕业院校" prop="school">
        <div
          class="relativeInput"
          v-for="(school, index) in form.school"
          :key="index"
        >
          <el-autocomplete
            v-model="form.school[index].name"
            :fetch-suggestions="querySchoolSearch"
            :trigger-on-focus="false"
            placeholder="请选择学校"
            :popper-append-to-body="false"
            @input="
              (val) => {
                $forceUpdate();
                searchSchoolLodash(val);
              }
            "
            @select="(option) => selectSchool(option, index)"
            style="width: calc(100% - 162px)"
          >
            <template slot-scope="{ item }">
              {{ item.name }}
            </template>
          </el-autocomplete>
          <el-select
            style="width: 150px; margin-left: 12px"
            v-model="form.school[index].qualification"
          >
            <el-option label="本科" :value="0"></el-option>
            <el-option label="硕士" :value="1"></el-option>
            <el-option label="博士" :value="2"></el-option>
          </el-select>
          <i
            v-if="index == 0"
            class="el-icon-circle-plus-outline absoluteIcon"
            @click="addGraduate"
          ></i>
          <i
            v-else
            class="el-icon-remove-outline absoluteIcon"
            @click="deleteGraduate(index)"
          ></i>
        </div>
      </el-form-item>
      <el-form-item label="当前公司" prop="company">
        <el-autocomplete
          v-model="searchCompanyKey"
          :fetch-suggestions="querySearch"
          :trigger-on-focus="false"
          placeholder="输入公司名"
          :popper-append-to-body="false"
          @input="searchLodash"
          @select="selectCompany"
          @keydown="form.company = ''"
          @keydown.native.enter="selectDefaultCompany"
          style="width: 100%"
        >
          <template slot-scope="{ item }" v-if="companys.length > 0">
            {{ item.name }}
          </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="曾经公司" prop="originalCompany">
        <div
          class="relativeInput"
          v-for="(company, index) in form.originalCompany"
          :key="index"
        >
          <el-autocomplete
            v-model="form.originalCompany[index]"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
            placeholder="输入公司名"
            :popper-append-to-body="false"
            @input="searchLodash"
            @select="(val) => (form.originalCompany[index] = val.name)"
            style="width: 100%"
          >
            <template slot-scope="{ item }" v-if="companys.length > 0">
              {{ item.name }}
            </template>
          </el-autocomplete>
          <i
            v-if="index == 0"
            class="el-icon-circle-plus-outline absoluteIcon"
            @click="addCompany"
          ></i>
          <i
            v-else
            class="el-icon-remove-outline absoluteIcon"
            @click="deleteCompany(index)"
          ></i>
        </div>
      </el-form-item>
      <el-form-item label="本人手持工牌照" prop="workCard">
        <el-upload
          ref="upload"
          :on-success="handleSuccess"
          :limit="1"
          :before-upload="beforeUpload"
          :on-remove="removeFile"
          :action="uploadUrl"
          accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
          style="display: inline-block; width: 100%"
          :data="{ isPublic: false }"
          :headers="{ 'X-Access-Token': token }"
          :show-file-list="false"
        >
          <el-button icon="el-icon-paperclip" size="small" slot="trigger"
            >上传照片
          </el-button>
          <span
            style="vertical-align: top; margin-left: 12px; cursor: pointer"
            @click="showViewer = true"
          >
            <i class="el-icon-info"></i>
            查看范例</span
          >
          <br />
          <img
            v-if="cover && cover != ''"
            :src="cover"
            alt=""
            style="max-width: 300px"
          />
        </el-upload>
        <el-image-viewer
          v-if="showViewer"
          :z-index="9999"
          :url-list="[require('@/assets/refer/example.png')]"
          :on-close="closeViewer"
        />
      </el-form-item>
      <div style="text-align: center">
        <el-button type="primary" @click="submitForm">提交资料</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import {
  getReferRegisterDetail,
  sendVerifyEmail,
  saveReferRegister,
} from "@/service/refer";
import _ from "lodash";
import { searchCompanys, getSchools } from "@/service/company";
export default {
  name: "referRegister",
  components: { ElImageViewer },

  data() {
    const schoolValidator = (rule, value, callback) => {
      let valid = true;
      for (let i = 0; i < this.form.school.length; i++) {
        if (
          this.form.school[i].name == "" ||
          this.form.school[i].qualification == ""
        ) {
          valid = false;
          break;
        }
        if (i == this.form.school.length - 1) {
          valid = true;
        }
      }
      if (!valid) {
        callback(new Error("请填写完整学校信息!"));
      } else {
        callback();
      }
    };
    const workCardValidator = (rule, value, callback) => {
      if (!this.form.workCard || this.form.workCard == "") {
        callback(new Error("请上传本人手持工牌照!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        name: "",
        email: "",
        school: [{ name: "", qualification: "" }],
        originalCompany: [""],
        company: "",
      },
      cover: "",
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      isAccess: false,
      countDown: 60,
      sendInterval: null,
      isSend: false,
      showViewer: false,
      companys: [],
      timeout: null,
      searchCompanyKey: "",
      schools: [],
      rules: {
        name: [
          {
            required: true,
            message: "请输入内推用户名",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入企业邮箱",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
        school: [
          {
            required: true,
            validator: schoolValidator,
            trigger: "change",
          },
        ],
        company: [
          {
            required: true,
            message: "请选择当前公司",
            trigger: "blur",
          },
        ],
        workCard: [
          {
            required: true,
            validator: workCardValidator,
            trigger: "change",
          },
        ],
      },
      originData: {},
    };
  },
  computed: {
    windowHeight() {
      return window.innerHeight + "px";
    },
  },
  mounted() {
    this.getReferRegisterDetail();
  },
  methods: {
    handleSuccess(res, file) {
      this.cover = URL.createObjectURL(file.raw);
      this.form.workCard = res.result.objectKey;
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isLt10M;
    },
    removeFile() {
      this.form.workCard = "";
      this.cover = "";
    },
    returnTo() {
      this.$router.push("/");
    },
    addGraduate() {
      this.form.school.push({ name: "", qualification: "" });
    },
    deleteGraduate(index) {
      this.form.school.splice(index, 1);
    },
    addCompany() {
      this.form.originalCompany.push("");
    },
    sendVerify() {
      //ajax successed after
      if (!this.form.email || this.form.email == "") {
        return this.$message.error("请输入企业邮箱！");
      }
      sendVerifyEmail(this.form.email).then((res) => {
        if (res.success) {
          this.$message.success("验证码已发送，请注意查收");
          this.isSend = true;
          this.sendInterval = setInterval(() => {
            this.countDown = this.countDown - 1;
            if (this.countDown == 0) {
              this.isSend = false;
              this.countDown = 60;
              clearInterval(this.sendInterval);
            }
          }, 1000);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    closeViewer() {
      this.showViewer = false;
    },
    getReferRegisterDetail() {
      getReferRegisterDetail().then((res) => {
        if (res.success) {
          this.originData = res.result;
          this.form.name = res.result.name ? res.result.name : "";
          this.form.email = res.result.email ? res.result.email : "";
          this.form.school = res.result.school
            ? res.result.school
            : [{ name: "", qualification: "" }];
          this.form.company = res.result.company ? res.result.company : "";
          this.searchCompanyKey = res.result.companyName
            ? res.result.companyName
            : "";
          this.form.originalCompany = res.result.originalCompany
            ? res.result.originalCompany
            : [""];
          this.cover = res.result.workCardAll ? res.result.workCardAll : "";
          this.form.workCard = res.result.workCard ? res.result.workCard : "";
        }
      });
    },
    querySearch(queryString, cb, type) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.companys);
      }, 1000);
    },
    searchLodash: _.debounce(function (val) {
      this.searchCompanys(val);
    }, 1000),
    searchCompanys(val) {
      searchCompanys(val).then((res) => {
        if (res.success) {
          this.companys = res.result;
        }
      });
    },
    selectCompany(option) {
      this.searchCompanyKey = option.name;
      this.form.company = option.id;
    },
    selectDefaultCompany() {
      if (this.companys.length > 0) {
        this.searchCompanyKey = this.companys[0].name;
        this.form.company = this.companys[0].id;
      } else {
        this.form.company = "";
      }
    },
    deleteCompany(index) {
      this.form.originalCompany.splice(index, 1);
    },

    querySchoolSearch(queryString, cb) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.schools);
      }, 1000);
    },
    searchSchools(val) {
      getSchools(val).then((res) => {
        if (res.success) {
          this.schools = res.result;
        }
      });
    },
    selectSchool(option, index) {
      this.form.school[index].name = option.name;
    },
    searchSchoolLodash: _.debounce(function (val) {
      this.searchSchools(val);
    }, 1000),

    submitForm() {
      let validSchool = true;
      for (let i = 0; i < this.form.school.length; i++) {
        if (
          this.form.school[i].name == "" ||
          this.form.school[i].qualification == ""
        ) {
          validSchool = false;
          break;
        }
        if (i == this.form.school.length - 1) {
          validSchool = true;
        }
      }
      if (!validSchool) {
        return this.$message.error("请填写完整学校信息");
      }
      console.log(this.form);
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveReferRegister(this.form).then((res) => {
            if (res.success) {
              this.$message.success("修改成功！");
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  min-height: 100%;
  padding: 50px 160px;
  border-radius: 8px;
  background: #fff;
  .relativeInput {
    position: relative;
    margin-bottom: 6px;
  }
  .absoluteIcon {
    position: absolute;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
  }
}
</style>